<template>
  <div class="mx-auto relative ml-2.5">
    <Navbar class="px-10" />
    <div class="w-2/3 relative left-1/2 transform -translate-x-1/2">
      <lottie-animation
        path="lotties/success.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :width="512"
        :height="512"
      />
      <p class="text-xl text-center mt-8 text-black">
        Onboarding kit has been saved successfully!
      </p>
      <div class="text-center mt-6">
        <router-link
          :to="{
            name: 'Dashboard',
          }"
          class="transition shadow duration-300 ease-out tracking-wider bg-white text-nColorBlue px-6 py-2
                 border border-nColorBlue rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none"
        >
          Back to dashboard
        </router-link>
        <router-link
          :to="{
            name: 'AddUsers',
          }"
          class="transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2
                 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none"
        >
          Add Users
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import Navbar from "@/components/Common/Navbar/Plain";

export default {
  components: { Navbar, LottieAnimation },
  props: {},
};
</script>

<style scoped></style>
